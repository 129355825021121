'use client';
import Image from 'next/image';
import ImageWithFallback from '@/src/features/ui/image/image-with-fallback';
import { ResponseNativeCard } from '@/src/interfaces/data-contracts';
import { RelLink } from '../../ui/rel-link';
import NativeCardTooltip from '../../ui/tooltip/native-card-tooltip';
import { useContext } from 'react';
import { DeviceContext } from '@/src/store/contexts/device-context';
import { ConditionalWrapper } from '@/src/utils/helpers/conditional-wrapper';

type Props = {
  item: ResponseNativeCard;
};
export const NativeCard: React.FC<Props> = ({ item }) => {
  const { isMobile } = useContext(DeviceContext);
  const imageProps = {
    src: isMobile
      ? item.nativeCardPictures?.mobileImagePath || item.nativeCardPictures?.tabletImagePath || ''
      : item.nativeCardPictures?.desktopImagePath || '',
    alt: item.nativeCardPictures?.altTitle + ' Kobi Kredisi',
    width: 1120,
    height: 146,
    className: 'shadow-faq',
  };

  return (
    <>
      {item.link && item.target ? (
        <>
          <RelLink href={item.link} target={item.target} className="h-auto w-full shadow-faq">
            <Image {...imageProps} loading="eager" priority decoding="async" style={{ objectFit: 'contain' }} />
          </RelLink>
          {item.isActiveInformationTooltip && (
            <NativeCardTooltip
              isActiveInfoTooltip={item.isActiveInformationTooltip ?? false}
              TooltipText={item.informationTooltipText ?? ''}
              isDesktop={!isMobile}
              tooltipTitle={item.informationTooltipText ?? ''}
              TooltipLeftToRight={item.informationTooltipLeftToRight ?? 0}
              TooltipTopToDown={item.informationTooltipTopToDown ?? 0}
            />
          )}
        </>
      ) : (
        <>
          <Image {...imageProps} loading="eager" priority decoding="async" style={{ objectFit: 'contain' }} />
          {item.isActiveInformationTooltip && (
            <NativeCardTooltip
              isActiveInfoTooltip={item.isActiveInformationTooltip ?? false}
              TooltipText={item.informationTooltipText ?? ''}
              isDesktop={!isMobile}
              tooltipTitle={item.informationTooltipText ?? ''}
              TooltipLeftToRight={item.informationTooltipLeftToRight ?? 0}
              TooltipTopToDown={item.informationTooltipTopToDown ?? 0}
            />
          )}
        </>
      )}
    </>
  );
};

export default NativeCard;
